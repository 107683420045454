<template>
    <div class="job-detail" id="printMe">
        <div class="detail-header-warpper">

            <div class="detail-header user-content flex">
                <div class="detail-info-bese">
                    <div class="base-status">招聘中</div>
                    <div class="base-name">{{jobDetail.workName}}<span
                            class="bigger">{{salesList[jobDetail.wagesId]}}</span></div>
                    <div class="oter-message">{{jobDetail.workAreaDetail}}<em
                            class="dolt"></em>{{jobDetail.workYears}}<em class="dolt"></em>{{jobDetail.workEducation}}
                    </div>
                    <div class="tag-container">
                        <div class="job-tags">
                            <span v-for="(item, index) in jobDetail.workWelfares"
                                  :key="`workWelfares${index}`">{{item}}</span>
                        </div>
                    </div>
                </div>
                <div class="job-submit flex">
                    <div class="btn-sub flex">

                        <div class="sub-o-left" v-if="!jobDetail.isCollection" @click="collectionJob">
                            <i class="icon-star normal"></i>收藏
                        </div>
                        <div class="sub-o-left" v-else @click="collectionJob">
                            <i class="icon-star normal"></i>已收藏
                        </div>
                        <div class="sub-o-right" v-if="!jobDetail.isDelivery&&!isPost&&!jobDetail.isInterview"
                             @click="openDialog(0)">立即投递
                        </div>
                        <div class="sub-o-right" style="margin-left:8px;" v-if="!jobDetail.isDelivery&&!isPost&&!jobDetail.isInterview"
                             @click="openDating">面试大厅
                        </div>
                        <div class="sub-o-right" style="margin-left:8px;" v-if="!jobDetail.isDelivery&&!isPost&&!jobDetail.isInterview"
                             @click="openDialog(1)">视频面试
                        </div>
                        <div @click="openSend(jobDetail.memberId)" class="sub-o-right" style="margin-left:8px;"
                             >微聊
                        </div>
                        <div v-if="jobDetail.isInterview" class="sub-o-right">已被邀请面试</div>
                        <div v-if="isPost || jobDetail.isDelivery && !jobDetail.isInterview" class="sub-o-right">已投递
                    </div>
                        <el-dialog
                                title="请选择你要投递的简历"
                                :visible.sync="dialogVisible"
                                width="35%"
                                :before-close="handleClose">
                                <div style="text-align:center; margin-bottom:15px;">
                                    <span>
                                        求职类型：
                                        <el-radio v-if="jobDetail.workType==1 || jobDetail.workType==3" v-model="workType" :label="1">全职</el-radio>
                                        <el-radio v-else-if="jobDetail.workType==2 || jobDetail.workType==3" v-model="workType" :label="2">灵活用工岗位</el-radio>
                                        <el-radio v-else-if="jobDetail.workType==5" v-model="workType" :label="5">共享用工</el-radio>
                                        <el-radio v-else v-model="workType" :label="1">全职</el-radio>
                                    </span>
                                    <span style="margin-left:18px;">
                                        到岗日期：
                                        <el-date-picker
                                        v-model="workTime"
                                        type="date"
                                        size="mini"
                                        :picker-options="option"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期">
                                        </el-date-picker>
                                    </span>

                                </div>
                            <div class="post-resume-box">

                                <!-- <el-button @click="chooseResume(item.id)" plain class="post-resume-box-item"
                                           :autofocus="true" type="primary" v-for="(item,index) in resumeList">
                                    {{item.resumeName}}
                                </el-button> -->
                                <div class="flex align-center">
                                    <div class="jianliitem  flex align-center justify-center" @click="chooseResume(item.id)" :class="currentResumeId==item.id?'jianlichecked':''" v-for="(item,index) in resumeList">
                                        {{item.resumeName}}
                                        <span><i class="el-icon-circle-check"></i></span>
                                    </div>
                                </div>
                            </div>
                            <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogVisible = false">取 消</el-button>
                            <el-button type="primary" @click="postJob">确 定</el-button>
                            </span>
                        </el-dialog>

                    </div>
                    <div class="btn-others flex">
                        <!-- <div class="btn-o-left" @click="openUserInfo"><i class="btn-o-icon resume"></i>完善在线简历</div>
                        <div class="btn-o-right" @click="openUserInfo"><i class="btn-o-icon upload"></i>上传附件简历</div>
                        <div class="btn-o-right" @click="myrpint"><i class="el-icon-printer upload"></i> 打印</div> -->
                        <!-- <div class="btn-o-right" v-print="'#printMe'"><i class="el-icon-printer upload"></i> 打印</div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="desc-warpper user-content flex" style="padding-bottom:30px;">
            <div class="j-desc-message">
                <div class="j-desc-bt">
                    <div class="bt-description">职位描述：</div>
                    <span v-html="jobDetail.workDetail"
                          style="white-space: pre-wrap;text-align: left;font-size: 16px;line-height: 20px"
                          class="bt-detail"></span>
                </div>
                <div class="j-desc-address">
                    <div class="desc-add-tit" style="margin-top:12px;">工作地址：</div>
                    <div class="desc-work-address">{{jobDetail.workAreaDetail}}{{jobDetail.address}}</div>
                    <!-- <el-amap class="amap-box" vid="map" :zoom="zoom" :center="center"> </el-amap> -->
                    <div class="desc-work-map">
                        <vue-map v-if="address" :address="address" :height="300"></vue-map>
                    </div>
                </div>
                <div class="jd_publisher">
                    <h3>职位发布者：</h3>
                    <div class="user-border flex">
                        <div class="user-round">
                            <img src="../../../assets/img/users/user-job.png" alt="">
                        </div>
                        <div class="uer-primary margin-right-sm">
                            <div class="leader-name">{{jobDetail.contacts}}</div>
                            <div class="leader-job">招聘负责人</div>
                        </div>
                        <div class="text-xl">
                            <span>联系电话:</span>
                            <span>{{jobDetail.contactsPhone}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="detail-slider">
                    <h3>公司基本信息</h3>
                    <div class="detail-info flex">
                        <div class="info-img">
                            <img :src="jobDetail.logo||require('../../../assets/img/users/company_logo.png')" alt="">
                        </div>
                        <div class="i-company-name text-overflow">{{jobDetail.companyName}}</div>
                    </div>
                    <div class="i-detail-item">
                        <i class="i-d-icon stage"></i>{{jobDetail.companyNatureName}}
                    </div>
                    <div class="i-detail-item">
                        <i class="i-d-icon number"></i>{{jobDetail.companyScale}}
                    </div>
                    <div class="i-detail-item">
                        <i class="i-d-icon servies"></i>{{jobDetail.industryName}}
                    </div>
                    <div class="i-detail-item">
                        <i class="i-d-icon address"></i>{{jobDetail.companyAreaName}}
                    </div>
                </div>
                <div class="like-jobs">
                    <h3>该公司招聘职位</h3>
                    <div class="some-list">
                        <div class="some-item flex" v-for="(item, index) in jobList"
                             :Key="`filterJobList${index}`" @click="handleSimilarJobDetail(item)">
                            <div class="company-base">
                                <div class="c-base-title">{{item.workName}}</div>
                                <div class="c-base-sales">{{item.wagesName}}</div>
                                <!-- <div class="c-base-cname text-overflow">{{item.companyName}}<em class="dolt-b"></em>上饶
                                </div> -->
                            </div>
                            <!-- <div class="c-base-logo">
                                <img :src="item.logo||require('../../../assets/img/users/company_logo.png')" alt="">
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
        title="发送消息"
        :visible.sync="showadd"
        width="30%"
        :before-close="sendClose">
        <div>
            <el-form ref="form" :model="form" label-width="0px">
                <el-form-item label="">
                    <el-input type="textarea" placeholder="请输入消息内容" :autosize="{ minRows: 2, maxRows: 4}" v-model="form.message"></el-input>
                </el-form-item>
                </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="sendClose">取 消</el-button>
            <el-button type="primary" @click="saveSend">确 定</el-button>
        </span>
        </el-dialog>


    </div>
</template>
<script>
    import VueMap from '../../../components/vueMap/Index'
    import userRequest from '../../../api/user'

    export default {
        components: {
            VueMap
        },
        created() {
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            this.$emit("setIndex", 1)
            this.userInfo = window.localStorage.getItem('userInfo')
        },
        mounted() {
            let query = this.$route.query
            if (!query.workId) {
                this.$router.back()
                return
            }
            this.workId = query.workId
            query.workName && (this.filterJobForm.workName = query.workName)
            this.getJobDetail()
            this.getJobListByWN()

        },
        data() {
            return {
                option:{
                    disabledDate:(time)=> {
                        return time.getTime() < Date.now()-1 * 24 * 3600 * 1000
                    }
                },
                showadd:false,
                workType:null,
                workTime:null,
                userInfo: "",
                isPost: false,
                form:{userId:null,memberId:null,pid:0,message:null,type:1},
                jobDetail: {},
                workId: null,  // 当前职位的id
                dialogVisible: false, // 有多份简历的时候的弹框
                resumeList: [], // 用户的简历列表
                currentResumeId: '', // 投递简历弹框里选择的简历
                address: '',
                center: ['116.407526', '39.90403'],
                salesList: ["面议", "1000-2000元", "2000-3000元", "3000-5000元", "5000-8000元", "8000-12000元", "12000-15000元", "15000-20000元", "20000元以上"],
                // center: '',
                zoom: 10,
                filterJobList: [],
                filterJobForm: {pageNum: 1, pageSize: 5, workName: ''},
                jobForm: {memberId: null, pageNum: 1, pageSize: 10},
                jobList: [],
                jobTotal: 0
            }
        },
        methods: {
            getJobListByCompanyId() {
                userRequest.getJobListByCompanyId(this.jobForm).then((ress) => {
                    if (ress.code === 200) {
                        this.jobList = ress.data.list
                        this.jobTotal = ress.data.total
                    }
                })
            },
            myrpint(){
                window.print();
            },
            sendClose(){
                this.showadd=false
            },
            saveSend(){
                userRequest.sendMessage(this.form).then(res=>{
                    if(res.success){
                        this.$message.success("发送成功！")
                        this.form.message=""
                    }
                })
                this.showadd=false
            },
            openSend(){

                if(this.userInfo==null || this.userInfo==''){
                    this.$message.error("请先登录！")
                    return
                }
                let useritem=JSON.parse(this.userInfo)
                this.form.userId=useritem.id
                this.form.memberId=this.jobDetail.memberId
                this.showadd=true
            },
            handleClose() {
                this.dialogVisible = false
            },
            chooseResume(resumeId) {
                this.currentResumeId = resumeId
            },
            getJobDetail() {
                userRequest.getJobDetail({workId: this.workId}).then(res => {
                    if (res.code === 200) {
                        this.jobDetail = res.data
                        if (this.jobDetail.workWelfare) {
                            this.jobDetail.workWelfares = this.jobDetail.workWelfare.split(',')
                        } else {
                            this.jobDetail.workWelfares = []
                        }
                        this.jobForm.memberId=this.jobDetail.memberId
                        this.getJobListByCompanyId()
                        const workAreaDetail = res.data.workAreaDetail.split('-')
                        // console.log('workAreaDetail', workAreaDetail)
                        this.address = `${workAreaDetail[workAreaDetail.length - 1]}${res.data.address}`
                    }
                })
            },
            getLngLatByAddress(val) {
                userRequest.getLngLatByAddress({address: val}).then((res) => {
                    if (res.code === 200) {

                    }
                })
            },
            getJobListByWN() {
                userRequest.getJobList(this.filterJobForm).then((res) => {
                    if (res.code === 200) {
                        this.filterJobList = res.data.list
                    }
                })
            },
            handleSimilarJobDetail(item) {
                this.$router.push({
                    path: '/user/u_blank',
                    query: {
                        workId: item.id,
                        workName: item.workName
                    }
                })
            },
            openDialog(e) {
                // console.log("获取到的用户的基本信息",this.userInfo)
                if (this.userInfo) {  // 有手机号，判断为 已经登录
                    userRequest.getUserResumeList().then(res => {
                        console.log("获取到的简历列表", res)
                        if(e==1){
                            this.$message.success("请等待企业邀请您在线面试！")
                        }
                        if (!res.data.idNumber) {
                            this.$message.warning("实名认证以后才能投递简历")
                            return;
                        } else {
                            if (res.data.resumeDOList.length === 0) {
                                this.$message.warning(" 您当前还没有简历")
                                return;
                            } else {  // 现在是有简历的，弹框让用户选择
                                console.log(56)
                                this.resumeList = res.data.resumeDOList

                            }
                        }
                        this.dialogVisible = true
                    })

                } else {
                    this.$message.warning("您还没有登录。")
                }

            },
            postJob() {  // 投递简历
                // this.$message.success("投递简历")
                // 获取简历列表
                if(this.workType==null){
                    this.$message.warning("请选择求职类型！")
                    return
                }
                if(this.workTime==null || this.workTime==undefined || this.workTime==''){
                    this.$message.warning("请选择到岗日期！")
                    return
                }
                userRequest.postResume({workId: this.workId, resumeId: this.currentResumeId,workType:this.workType,workTime:this.workTime}).then(res => {
                    console.log(res)
                    if (res.code === 200) {
                        console.log("投递成功", res)
                        this.isPost = true
                        this.$message.success("投递成功")
                        this.dialogVisible = false
                        this.workTime=null
                        this.workType=null
                    }else{
                        this.$message.warning(res.message)
                    }
                }).catch(err=>{
                    console.log(err)
                })

            },
            collectionJob() {  // 收藏当前的职位
                 // this.jobDetail.isCollection   true:未收藏  false:已收藏
                let isCollection = this.jobDetail.isCollection
                if(!isCollection){  // 未收藏，发送收藏请求
                    userRequest.collectionResume({type:1,workId:this.workId}).then(res=>{
                        if(res.code === 200){
                            this.$message.success("收藏成功")
                            this.jobDetail.isCollection = !this.jobDetail.isCollection
                        }
                    })
                }else{
                    userRequest.collectionResume({type:2,workId:this.workId}).then(res=>{
                        if(res.code === 200){
                            this.$message.success("取消成功")
                            this.jobDetail.isCollection = !this.jobDetail.isCollection
                        }
                    })
                }
            },
            openUserInfo() { // 跳转到个人信息
                this.$router.push({
                    path: '/user/resumes'
                })
            },
            openDating(){//打开面试大厅

                //joinUserNumber
                if(this.jobDetail.joinUserNumber>=8){
                    this.$message.warning("面试大厅人数已满！")
                    return
                }

                this.$router.push({path:"/user/tempInterview",query:{workId:this.workId}})
            }
        },

        watch: {
            // address(val) {
            //     console.log('address', this.address)
            //     this.getLngLatByAddress('广州天河区珠江新城')
            // }
        }
    }
</script>
<style lang="scss" scoped>

.jianliitem{ position: relative; cursor: pointer; width: 100px; height: 100px; background: #ecf5ff; border: #b3d8ff solid 1px; border-radius:5px;}
.jianliitem span{ display: none;}
.jianlichecked span{ position: absolute; bottom: 0; right: 0; display: block; width: 20px; height: 20px; }
.jianlichecked span i{ font-size: 18px; color: #04a835;}



    @import "../../../assets/css/global.scss";

    .post-resume-box {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        min-width: 360px;

        &-item {
            min-width: 120px;
            height: 160px;
            /*background-color: #1f8ce4;*/
            font-size: 16px;
        }
    }


    .job-detail {
        height: 100%;

        .amap-box {
            width: 100%;
            height: 300px;
            margin-top: 20px;
        }

        .detail-header-warpper {
            background: #444c60 url(../../../assets/img/users/detail-bg.jpg) center center no-repeat;
            padding: 24px 0;

            .detail-header {
                justify-content: space-between;

                .detail-info-bese {
                    width: 800px;

                    .base-status {
                        color: rgba(255, 255, 255, .7);
                        font-size: 14px;
                        line-height: 20px;
                    }

                    .base-name {
                        font-size: 32px;
                        line-height: 45px;
                        padding: 11px 0 8px;
                        color: #fff;

                        .bigger {
                            color: $main;
                            margin-left: 10px;
                            font-weight: 600;
                        }
                    }

                    .oter-message {
                        color: rgba(255, 255, 255, .7);
                        margin-bottom: 10px;
                    }

                    .tag-container {
                        padding-top: 8px;

                        .job-tags {
                            span {
                                display: inline-block;
                                padding: 0 15px;
                                margin-right: 10px;
                                margin-bottom: 10px;
                                border: 1px solid $green;
                                color: $green;
                                height: 23px;
                                line-height: 23px;
                                border-radius: 13px;
                                margin-top: 0;
                                vertical-align: top;
                            }
                        }
                    }
                }

                .job-submit {
                    flex-direction: column;
                    justify-content: center;
                    width: 380px;

                    .btn-sub {
                        margin-bottom: 30px;
                        width: 380px;
                        color: #fff;
                        font-size: 16px;

                        .sub-o-left {
                            box-sizing: border-box;
                            cursor: pointer;
                            width: 143px;
                            height: 45px;
                            margin-right: 10px;
                            text-align: center;
                            line-height: 45px;
                            border: 1px solid #ad4128;
                            background: rgba($color: #ff704f, $alpha: 0.2);

                            &:hover {
                                background: rgba($color: #ff704f, $alpha: 0.4);
                            }

                            .icon-star {
                                display: inline-block;
                                width: 16px;
                                margin-right: 7px;
                                height: 16px;

                                &.normal {
                                    position: relative;
                                    top: 2px;
                                    background: url(../../../assets/img/users/star.png) no-repeat;
                                    background-size: 100% 100%;
                                }

                                &.s-active {
                                    position: relative;
                                    top: 2px;
                                    background: url(../../../assets/img/users/star_active.png) no-repeat;
                                    background-size: 100% 100%;
                                }
                            }
                        }

                        .sub-o-right {
                            box-sizing: border-box;
                            cursor: pointer;
                            width: 143px;
                            height: 45px;
                            text-align: center;
                            line-height: 45px;
                            background: $main;

                            &:hover {
                                background: $hover;
                            }
                        }
                    }

                    .btn-others {
                        width: 370px;
                        justify-content: space-between;
                        font-size: 16px;
                        color: $green;

                        .btn-o-left {
                            cursor: pointer;
                        }

                        .btn-o-right {
                            cursor: pointer;
                        }

                        .btn-o-icon {
                            display: inline-block;
                            vertical-align: -2px;
                            width: 18px;
                            height: 15px;
                            margin-right: 8px;

                            &.resume {
                                background: url(../../../assets/img/users/icon-resume-login.png) no-repeat;
                                background-size: 100% 100%;
                            }

                            &.upload {
                                background: url(../../../assets/img/users/icon-upload-login.png) no-repeat;
                                background-size: 100% 100%;
                            }
                        }
                    }
                }
            }
        }

        .desc-warpper {
            margin-top: 20px;

            .j-desc-message {
                box-sizing: border-box;
                padding: 0 20px;
                width: 846px;
                background: #fff;
                overflow: hidden;

                .j-desc-bt {
                    padding: 15px 0 10px;
                    color: #333;
                    word-wrap: break-word;

                    .bt-description {
                        font-size: 16px;
                        font-weight: 700;
                        color: #333;
                        line-height: 16px;
                        margin-bottom: 22px;
                        margin-top: 5px;

                        .bt-detail {
                            line-height: 22px;
                            white-space: pre-wrap;
                            text-align: left;

                            p {
                                line-height: 25px;
                            }
                        }
                    }
                }

                pre {
                    line-height: 22px;
                }

                .j-desc-address {
                    .desc-add-tit {
                        font-size: 16px;
                        color: #333;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }

                    .desc-work-address {
                        color: #333;
                    }

                    .desc-work-map {
                        min-height: 300px;
                    }
                }

                .jd_publisher {
                    padding: 5px 0 10px;
                    position: relative;
                    margin-top: 38px;
                    margin-bottom: 40px;

                    h3 {
                        font-size: 16px;
                        line-height: 22px;
                        color: #333;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }

                    .user-border {
                        align-items: center;
                    }

                    .user-round {
                        width: 60px;
                        height: 60px;
                        overflow: hidden;
                        border-radius: 50%;

                        img {
                            width: 60px;
                            height: 60px;
                        }
                    }

                    .uer-primary {
                        margin-left: 20px;

                        .leader-name {
                            font-size: 16px;
                            margin-bottom: 10px;
                        }

                        .leader-job {
                            font-size: 14px;
                            color: #999;
                        }
                    }
                }
            }

            .detail-slider {
                margin-left: 5px;
                width: 347px;
                height: 320px;
                box-sizing: border-box;
                padding: 20px;
                background: #fff;

                h3 {
                    font-size: 16px;
                    line-height: 22px;
                    color: #333;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                .detail-info {
                    align-items: center;
                    margin-bottom: 27px;

                    .info-img {
                        width: 60px;
                        height: 60px;
                        margin-right: 18px;
                        border-radius: 10px;

                        img {
                            width: 60px;
                            height: 60px;
                        }
                    }

                    .i-company-name {
                        font-size: 15px;
                        color: #414a60;
                    }
                }

                .i-detail-item {
                    line-height: 20px;
                    margin-bottom: 26px;

                    .i-d-icon {
                        display: inline-block;
                        vertical-align: -4px;
                        width: 17px;
                        height: 17px;
                        margin-right: 25px;

                        &.stage {
                            background: url(../../../assets/img/users/process_icon_2.png) no-repeat;
                            background-size: 100% 100%;
                        }

                        &.number {
                            background: url(../../../assets/img/users/number_icon_3.png) no-repeat;
                            background-size: 100% 100%;
                        }

                        &.servies {
                            background: url(../../../assets/img/users/type_icon_1.png) no-repeat;
                            background-size: 100% 100%;
                        }

                        &.address {
                            background: url(../../../assets/img/users/address_icon_4.png) no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
            }

            .like-jobs {
                margin-left: 5px;
                margin-top: 20px;
                width: 347px;
                box-sizing: border-box;
                padding: 20px;
                background: #fff;

                h3 {
                    font-size: 16px;
                    line-height: 22px;
                    color: #333;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                .some-list {
                    .some-item {
                        cursor: pointer;
                        padding: 10px 0;
                        border-bottom: 1px solid #f3f3f3;
                        margin-bottom: 10px;
                        transition: all 0.2s;

                        &:hover {
                            transform: translateY(-2px);
                            border-bottom: 1px solid $main;
                        }

                        .company-base {
                            width: 266px;

                            .c-base-title {
                                color: #424a5e;
                            }

                            .c-base-sales {
                                margin-top: 5px;
                                color: $main;
                            }

                            .c-base-cname {
                                margin-top: 13px;
                                color: #9fa3af;
                            }
                        }

                        .c-base-logo {
                            margin-left: 10px;
                            width: 60px;
                            height: 60px;
                            border-radius: 10px;
                            border: 1px solid #f2f5fa;
                            overflow: hidden;

                            img {
                                width: 60px;
                                height: 60px;
                            }
                        }
                    }
                }

            }
        }
    }
</style>
